/* ------------------------ TEXTOS ------------------------ */

.title-main {
    font-family: "AvenirNextRounded";
    color: #333 !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}

/* ------------------------ BOTONES ------------------------ */

.btn-table{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #188dcd !important;
    border: solid 1px #4fa5c2 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    min-width: 100px !important;
}
.btn-table:hover {
    background-color: #0276a9 !important;
    border: 1px solid #155463 !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-confirm{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #188dcd !important;
    border: solid 1px #4fa5c2 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    width: 150px !important;
}
.btn-confirm:hover {
    background-color: #0276a9 !important;
    border: 1px solid #155463 !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-cancel{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #f1a32d !important;
    border: solid 1px #f1a32d !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    width: 150px !important;
}
.btn-cancel:hover {
    background-color: #ff9700 !important;
    border: 1px solid #155463 !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-refuse{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #ee7273 !important;
    border: solid 1px #dd5c5b !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    width: 150px !important;
}
.btn-refuse:hover {
    background-color: #dd5c5b !important;
    border: 1px solid #155463 !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-secondary{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #FFFFFF !important;
    border: solid 1px #188dcd !important;
    color: #188dcd !important;
    cursor: pointer;
    transition: .5;
    text-transform: none !important;
    min-width: 150px !important;
}
.btn-secondary:hover {
    background-color: #dcf0f8 !important;
    border: 1px solid #188dcd !important;
    color: #188dcd !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-back-container{
    border: 1px solid #c4bfbf !important;
    border-radius: 5px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;
}
.btn-back-text{
    color: #188dcd;
    font-size: 14px;
    font-weight: 600;
}

.paragraph{
    font-family: "AvenirNextRounded";
    font-size: 13px !important;
    color: #5b5b5b !important;
}

.text-info{
    font-family: "AvenirNextRounded";
    font-size: 13px !important;
    color: #5b5b5b !important;
}

.text-info-red{
    font-family: "AvenirNextRounded";
    font-size: 13px !important;
    color: #e91919 !important;
}