.contract-containers-btn{
    margin-top: 10px !important;
    justify-content: space-between !important;
    display: flex !important;
}
.contract-title{
    width: 300px;
    font-size: 12px !important;
    text-transform: uppercase !important;
}
.contract-description-title{
    width: 600px;
    font-size: 10px !important;
}
.contract-container-subtitle{
    max-width: 600px;
    display: flex;
    justify-content: start;
    align-items: center;
}
